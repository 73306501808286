<template>
  <div class>
    <div class="content-body content-body-profile">
      <div class="profile-sidebar">
        <div class="content-header pd-t-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/human-resources/employee">Employee</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Employee Detail</li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- content-header -->
        <div class="profile-sidebar-header">
          <div class="avatar">
            <img
              :src="employeeProfile.img ? employeeProfile.img : '/assets/img/user_icon.png'"
              class="rounded-circle"
              alt
            />
          </div>
          <h5>{{ employeeProfile.name }}</h5>
          <h6 class="mb-1">{{ employeeProfile.designation }}</h6>
          <div class="d-flex">
            <h6>{{ employeeProfile.employee_id }} -</h6>
            <div class="pd-l-5 tx-medium">
              <div class="tx-primary tx-500" v-if="(employeeProfile.status == 1)">Active</div>
              <div class="tx-danger tx-500" v-else>In-Active</div>
            </div>
          </div>
          <div class="d-flex align-self-stretch mt-1">
            <a href="#" class="btn btn-sm btn-primary btn-uppercase flex-fill">Email</a>
            <a
              href="javascript:;"
              class="btn btn-white btn-sm btn-uppercase flex-fill mg-l-5"
              @click="edit('editProfile', employeeProfile.id)"
            >Edit</a>
          </div>
        </div>

        <div class="profile-sidebar-body">
          <label class="content-label">Contact Detail</label>
          <ul class="list-unstyled profile-info-list mg-b-0">
            <li v-if="employeeProfile.current_address">
              <i class="fas fa-map-marker-alt"></i>
              <span class="tx-color-03 pl-2">
                {{
                employeeProfile.current_address
                }}
              </span>
            </li>
            <li v-if="employeeProfile.mobile_no">
              <i class="fas fa-phone"></i>
              <span class="tx-color-03 pl-2">
                {{ employeeProfile.mobile_no }}
              </span>
            </li>
            <li v-if="employeeProfile.email">
              <i class="fas fa-envelope-open"></i>
               <span class="tx-color-03 pl-2">
                {{ employeeProfile.email }}
              </span>
            </li>
          </ul>
          <hr class="mg-y-10" />
          <label class="content-label">Personal Detail</label>
          <ul class="list-unstyled profile-info-list mb-0">
            <li v-if="employeeProfile.dob">
              <span class="tx-color-03">DOB: {{ employeeProfile.dob }}</span>
            </li>
            <li v-if="employeeProfile.sex">
              <span class="tx-color-03">Sex: {{ employeeProfile.sex }}</span>
            </li>
            <li v-if="employeeProfile.blood_group">
              <span class="tx-color-03">Blood Group: {{ employeeProfile.blood_group }}</span>
            </li>
            <li v-if="employeeProfile.citizenship_no">
              <span class="tx-color-03"></span>
              Citizenship:
              {{ employeeProfile.citizenship_no }}
            </li>
            <li v-if="employeeProfile.pan_no">
              <span class="tx-color-03">PAN: {{ employeeProfile.pan_no }}</span>
            </li>
            <li v-if="employeeProfile.permanent_address">
              <span class="tx-color-03"></span>
              Permanenet Address:
              {{ employeeProfile.permanent_address }}
            </li>
          </ul>

          <hr class="mg-y-10" />
          <label class="content-label">Other Detail</label>
          <ul class="list-unstyled profile-info-list mb-0">
            <li v-if="employeeProfile.joining_date">
              <span class="tx-color-03">Joining Date: {{ employeeProfile.joining_date }}</span>
            </li>
            <li v-if="employeeProfile.branch_id">
              <span class="tx-color-03">Joining Branch: {{ employeeProfile.branch.name }}</span>
            </li>
            <li v-if="employeeProfile.salary">
              <span class="tx-color-03">Starting Salary: {{ parseDigitForSlip(employeeProfile.salary) }}</span>
            </li>
            <li v-if="employeeProfile.user">
              <span class="tx-color-03">Added By: {{employeeProfile.user.name}}</span>
            </li>
            <li v-if="employeeProfile.opening_due">
              <span class="tx-color-03">Opening Balance: {{ parseDigitForSlip(employeeProfile.opening_due) }}</span>
            </li>
            <!-- <li v-if="employeeProfile.created_at">
              <span class="tx-color-03">Employee Added: {{ employeeProfile.created_at }}</span>
            </li> -->
          </ul>

          <hr class="mg-y-10" />
          <label class="content-label">Parents Detail</label>
          <ul class="list-unstyled profile-info-list">
            <li v-if="employeeProfile.father_name">
              <span class="tx-color-03">Father Name: {{ employeeProfile.father_name }}</span>
            </li>
            <li v-if="employeeProfile.father_mobile_no">
              <span class="tx-color-03">Father Mobile: {{ employeeProfile.father_mobile_no }}</span>
            </li>
            <li v-if="employeeProfile.mother_name">
              <span class="tx-color-03">Mother Name: {{ employeeProfile.mother_name }}</span>
            </li>
            <li v-if="employeeProfile.mother_mobile_no">
              <span class="tx-color-03">Mother Mobile: {{ employeeProfile.mother_mobile_no }}</span>
            </li>
          </ul>
        </div>
        <!-- profile-sidebar-body -->
      </div>
      <!-- profile-sidebar -->
      <div class="profile-body">
        <div class="profile-body-header">
          <div class="nav-wrapper">
            <ul class="nav nav-line" id="profileTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="overview-tab"
                  data-toggle="tab"
                  @change="getEmployeeAttendance()"
                  href="#overview"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >Overview</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="projects-tab"
                  data-toggle="tab"
                  href="#bills"
                  @click="getEmployeeAttendence()"
                  role="tab"
                  aria-controls="projects"
                  aria-selected="false"
                >Attendance</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="people-tab"
                  data-toggle="tab"
                  href="#receipts"
                  role="tab"
                  @click="getPayroll()"
                  aria-controls="people"
                  aria-selected="false"
                >Payments</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="getLedger()"
                  id="ledger-tab"
                  data-toggle="tab"
                  href="#ledger"
                  role="tab"
                  aria-controls="ledger"
                  aria-selected="false"
                >Ledger</a>
              </li>
            </ul>
          </div>
          <!-- nav-wrapper -->
        </div>
        <!-- profile-body-header -->
        <div class="tab-content p-3">
          <div id="overview" class="tab-pane active show">
            <div class="stat-profile">
              <div class="stat-profile-body">
                <div class="row row-xs px-2">
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-primary-light">
                      <h3
                        class="tx-normal tx-sans tx-spacing--4 tx-primary mg-b-5"
                      > {{ parseDigitForSlip(employeeProfile.closing_due) }}</h3>
                      <p class="font-s tx-color-02 mg-b-0">Total Salary Due</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-teal-light">
                      <h3
                        class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-teal"
                      > {{ parseDigitForSlip(employeeProfile.advance) }}</h3>
                      <p class="font-s tx-color-03 mg-b-0">Total Advance Amount</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-pink-light">
                      <h3 class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-pink">{{employeeProfile.working_days}}</h3>
                      <p class="font-s tx-color-03 mg-b-0">Total Working Period</p>
                    </div>
                  </div>
                </div>
                <!-- row -->
              </div>
              <!-- stat-profile-body -->
            </div>
            <!-- stat-profile -->

            <label class="content-label content-label-lg mt-3 tx-color-01">Description</label>
            <p class="tx-color-03 m-0">{{ employeeProfile.description }}</p>

            <hr class="mt-3" />
          </div>
          <div class="tab-pane fade" id="bills" role="tabpanel" aria-labelledby="contact-tab5">
            <div class="bg-theam-secondary table-search-head">
              <div class="row">
                <div class="filter-input col-md-9 d-flex mr-0 pr-0">
                  <select name="size">
                    <option value="1000">1000</option>
                  </select>
                  <input type="text" class="ml-2 filter-input" placeholder="Search..." />
                </div>
                <div class="col-md-3">
                  <div class="text-right">
                    <button class="btn-icons">
                      <i class="fas fa-print pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-pdf pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-excel pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table class="table table2 table-hover">
              <thead>
                <tr>
                  <th class="wd-20p table-start-item">Month</th>
                  <th class="wp-15p">Month Days</th>
                  <th class="wd-15p">Present</th>
                  <th class="wd-15p">Absent</th>
                  <th class="wd-15p">Holiday</th>
                  <th class="wd-10p">Half</th>
                  <th class="wd-10p">Late</th>
                </tr>
              </thead>
              <tbody v-if="!attendence_params.loading && employeeAttendence.length > 0">
                <tr v-for="(attendence) in employeeAttendence" :key="attendence.id">
                  <td class="table-start-item">{{attendence.month}}</td>
                  <td>{{attendence.total_days}}</td>
                  <td>{{attendence.present}}</td>
                  <td>{{attendence.absent}}</td>
                  <td>{{attendence.holiday}}</td>
                  <td>{{attendence.half}}</td>
                  <td>{{attendence.late}}</td>
                </tr>
              </tbody>
              <tbody v-else-if="attendence_params.error">
            <tr>
              <td colspan="11" style="text-align:center;">{{attendence_params.error_message}}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!attendence_params.loading && employeePayroll.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="attendence_params.loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
            </table>
          </div>
          <!---individual content-->
          <div class="tab-pane fade" id="receipts" role="tabpanel" aria-labelledby="contact-tab5">
            <div class="bg-theam-secondary table-search-head">
              <div class="row">
                <div class="filter-input col-md-9 d-flex mr-0 pr-0">
                  <select name="size" v-model="payroll_params.pageIndex" @change="filterPayroll">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="">All</option>
                  </select>
                  <div class="ml-2">
                    <label for class="filter-lavel mb-0 mr-1">From</label>
                    <input type="date" placeholder="From" class="filter-input" v-model="payroll_params.from" @change="filterPayroll" />
                    <label for class="filter-lavel mb-0 ml-1">To</label>
                    <input type="date" placeholder="To" class="ml-1 filter-input" v-model="payroll_params.to" @change="filterPayroll" />
                  </div>
                  <input type="text" class="ml-2 filter-input" placeholder="Search..." v-model="payroll_params.searched" @keyup="searchPayrolllKeyword" />
                </div>
                <div class="col-md-3">
                  <div class="text-right">
                    <button class="btn-icons">
                      <i class="fas fa-print pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-pdf pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-excel pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table class="table table2 table-hover">
              <thead>
                <tr>
                  <th class="wd-5px table-start-item">SN.</th>
                  <th class="wd-15p">Date</th>
                  <th class="wd-15p">Receipt ID</th>
                  <th class="wd-20p">Paid As</th>
                  <th class="wd-20p">Payment Mode</th>
                  <th class="wd-15p">Paid Amount</th>
                  <th class="wd-10px text-right table-end-item">Action</th>
                </tr>
              </thead>
              <tbody v-if="!payroll_params.loading && employeePayroll.length > 0">
                <tr v-for="(payroll,index) in employeePayroll" :key="payroll.id">
                  <td>{{index+payroll_params.pageSerialNo}}</td>
                  <td>{{payroll.date}}</td>
                  <td>{{payroll.receipt_no}} <span v-if="payroll.status == 1" class="tx-danger">-Canceled</span></td>
                  <td>
                    <span v-if="payroll.is_advance == 0">Salary</span>
                    <span v-else>Advance</span>
                  </td>
                  <td>
                    {{payroll.payment_method.title}}
                    <span
                      v-if="payroll.bank_id"
                    >-{{payroll.bank.bank}}</span>
                  </td>
                  <td>{{parseDigitForSlip(payroll.paid_amt)}}</td>
                   <td class="text-right table-end-item" v-if="checkIfAnyPermission(['view_salary_payroll'])">
                <a href="javascript:;" v-if="checkSinglePermission('view_salary_payroll')" class="mr-3">
                  <i
                    class="fas fa-eye tx-success"
                    title="View"
                    @click="viewPayrollSlip('profilePayrollSlip', payroll.id)"
                  ></i>
                </a></td>
                </tr>
              </tbody>
              <tbody v-else-if="payroll_params.error">
            <tr>
              <td colspan="11" style="text-align:center;">{{payroll_params.error_message}}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!payroll_params.loading && employeePayroll.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="payroll_params.loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
            </table>
            <Paginate
          v-model="payroll_params.page"
          :pageCount="payroll_params.totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallbackPayroll"
          v-if="payroll_params.totalPageCount > 1"
        />
        <PayrollSlip />
          </div>
          <!--individual-content-->
          <div class="tab-pane fade" id="ledger" role="tabpanel" aria-labelledby="contact-tab5">
            <div class="bg-theam-secondary table-search-head">
              <div class="row">
                <div class="filter-input col-md-9 d-flex mr-0 pr-0">
                  <div>
                    <label for class="filter-lavel mb-0 mr-1">From</label>
                    <input type="date" placeholder="From" class="filter-input" v-model="ledger_params.from" @change="filterLedger"/>
                    <label for class="filter-lavel mb-0 ml-1">To</label>
                    <input type="date" placeholder="To" class="ml-1 filter-input" v-model="ledger_params.to" @change="filterLedger" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="text-right">
                    <button class="btn-icons">
                      <i class="fas fa-print pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-pdf pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-excel pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table class="table table2 table-hover" >
              <thead>
                <tr>
                  <th class="wd-15p table-start-item">Date</th>
                  <th class="wd-35p">Description</th>
                  <th class="wd-15p text-right table-end-item">Dr. Amount</th>
                  <th class="wd-15p text-right table-end-item">Cr. Amount</th>
                  <th class="wd-20p text-right table-end-item">Balance</th>
                </tr>
              </thead>
              <tbody  v-if="!ledger_params.loading && employeeLedger.length > 0"> 
                <tr v-for="ledger in employeeLedger" :key="ledger.id">
                  <th scope="row" class="table-start-item">{{ledger.date}}</th>
                  <td>{{ledger.description}}</td>
                  <td class="text-right table-end-item">{{ (ledger.debit =='-')?'-':parseDigitForSlip(ledger.debit) }}</td>
            <td class="text-right table-end-item">{{ (ledger.credit =='-')?'-':parseDigitForSlip(ledger.credit) }}</td>
            <td class="text-right table-end-item">{{Math.abs(parseDigitForSlip(ledger.balance)) }} {{ ledger.sign }}.</td>
                </tr>
                 <tr>
            <td></td>
            <td>Total</td>
            <td class="text-right table-end-item">{{parseDigitForSlip(ledger_params.totals.debit_total)}}</td>
            <td class="text-right table-end-item">{{parseDigitForSlip(ledger_params.totals.credit_total)}}</td>
            <td class="text-right table-end-item">{{Math.abs(parseDigitForSlip(ledger_params.totals.total_balance))}} {{ledger_params.totals.total_sign }}</td>
          </tr>
              </tbody>
              <tbody v-else-if="ledger_params.error">
            <tr>
              <td colspan="11" style="text-align:center;">{{ledger_params.error_message}}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!ledger_params.loading && employeeLedger.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="ledger_params.loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
            </table>
          </div>
        </div>
        <!-- tab-content -->
      </div>
      <!-- profile-body -->
    </div>
    <!-- content-body -->
    <EmployeeCreate @parent-event="getEmployeeData"/>
  </div>
  <!-- content -->
</template>
<script>
import EmployeeCreate from "./create";
import { mapGetters } from "vuex";
import Services from "../Services/Services";
import Paginate from "vuejs-paginate";
import _ from "lodash";
import PayrollSlip from "../SalaryPayroll/slip";
export default {
  components: {
    EmployeeCreate,Paginate,PayrollSlip
  },
  computed: {
    ...mapGetters([
      "dataLists",
      "dataLists1",
      "dataLists2",
      "dataId",
      "today",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("employees", ["employeeProfile", "employeePayroll","employeeLedger","employeeAttendence"]),
  },
  data() {
    return {
      payroll_params: {
        pageIndex: 10,
        id:this.$route.params.id,
        branch: "",
        from:"",
        to:"",
        searched: "",
        offset:0,
        loading: false,
        page: 1,
        error: false,
        error_message: "",
        totalPageCount: 0,
        pageSerialNo: 1,
      },
      ledger_params: {
        id:this.$route.params.id,
        from:"",
        totals:{},
        to: "",
        loading: false,
        error: false,
        error_message: "",
      },
      attendence_params:{
        id:this.$route.params.id,
        loading:false,
        error:false,
        error_message:"",
      }
    };
  },
   mounted() {
    this.getEmployeeData();
    this.ledger_params.to = this.today;
  },
  methods: {
     clickCallbackPayroll: function (page) {
      if (page == 1) {
        this.payroll_params.pageSerialNo = 1;
      } else {
        this.payroll_params.pageSerialNo = (page - 1) * this.payroll_params.pageIndex + 1;
      }
      this.payroll_params.offset = (page - 1) * this.payroll_params.pageIndex;
      this.getPayroll();
    },
    viewPayrollSlip(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    getEmployeeData() {
      this.$store.commit("employees/setEmployeeProfile", []);
      Services.getEmployeeProfileData(this.$route.params.id)
        .then((res) => {
          this.$store.commit("employees/setEmployeeProfile", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPayroll() {
      this.payroll_params.loading = true;
      Services.getEmployeeePayrollList(this.payroll_params)
        .then((res) => {
          this.$store.commit(
            "employees/setEmployeePayroll",
            res.data.data.data
          );
          this.payroll_params.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.payroll_params.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.payroll_params.error_message = "Access Denied !!!";
            } else {
              this.payroll_params.error_message =
                "Error fetching data from the server.";
            }
          }
        }).finally(()=>{
          this.payroll_params.loading=false;
        });
    },
    filterPayroll(){
      this.payroll_params.page=1;
      this.payroll_params.offset=0;
      this.payroll_params.pageSerialNo=1;
      this.getPayroll();
    },
    searchPayrolllKeyword: _.debounce(function () {
      this.payroll_params.page=1;
      this.payroll_params.offset=0;
      this.payroll_params.pageSerialNo=1;
      this.getPayroll();
    }, 500),
    getLedger() {
      this.ledger_params.loading = true;
      Services.getEmployeeLedgerList(this.payroll_params)
        .then((res) => {
          this.$store.commit(
            "employees/setEmployeeLedger",
            res.data.data.data
          );
          this.ledger_params.totals = res.data.data;
        })
        .catch((err) => {
          this.ledger_params.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.ledger_params.error_message = "Access Denied !!!";
            } else {
              this.ledger_params.error_message =
                "Error fetching data from the server.";
            }
          }
        }).finally(()=>{
          this.ledger_params.loading=false;
        });
    },
    filterLedger(){
      this.ledger_params.loading = true;
      Services.getEmployeeLedgerFilter(this.ledger_params).then(res=>{
          this.$store.commit(
            "employees/setEmployeeLedger",
            res.data.data
          );
        })
        .catch((err) => {
          this.ledger_params.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.ledger_params.error_message = "Access Denied !!!";
            } else {
              this.ledger_params.error_message =
                "Error fetching data from the server.";
            }
          }
        }).finally(()=>{
            this.ledger_params.loading=false;
        });
    },
    getEmployeeAttendence(){
      this.attendence_params.loading = true;
      Services.getEmployeeAttendenceList(this.attendence_params).then(res=>{
          this.$store.commit("employees/setEmployeeAttendence",res.data.data);
        })
        .catch((err) => {
          this.attendence_params.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.attendence_params.error_message = "Access Denied !!!";
            } else {
              this.attendence_params.error_message =
                "Error fetching data from the server.";
            }
          }
        }).finally(()=>{
            this.attendence_params.loading=false;
        });
    }

  },
};
</script>
<style scoped>
</style>